import { UserListItemTypes } from '@typedef/components/User/user.types';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {};

type ArtistType = UserListItemTypes & {
  nickname: string;
  uploadNumber: number;
};

const PartnersDetail = (props: Props) => {
  const navigate = useNavigate();

  const [user, setUser] = useState<ArtistType>({
    userUid: '1',
    nickname: 'testUser',
    email: 'testUser@gmail.com',
    name: 'testUser',
    birth: '1997-11-16',
    phone: '010-1111-1111',
    gender: 'M',
    deleteDate: null,
    uploadNumber: 12,
  });

  const validation = useMemo(
    () =>
      Boolean(
        user.birth && user.email && user.name && user.nickname && user.phone,
      ),
    [user],
  );

  const onInputChagned = useCallback((key: keyof ArtistType, value: string) => {
    setUser((prev) => ({ ...prev, [key]: value }));
  }, []);

  const onStoreClicked = useCallback(() => {
    if (!validation) {
      alert('유저의 정보를 비우지 마십시오');
      return;
    }
    alert('유저의 정보가 수정되었습니다.');
    navigate(-1);
  }, [validation, navigate]);

  return (
    <div className='user-detail-root'>
      <div className='title-section'>
        <div className='title-container'>
          <div className='name'>{user.name}</div>
          <div className='email'>{user.email}</div>
        </div>
        <div className='button-wrapper'>
          <button className='store' onClick={onStoreClicked}>
            저장하기
          </button>
        </div>
      </div>
      <div className='content-section'>
        <div className='column'>
          <div className='input-item'>
            <span className='label'>이름</span>
            <input
              type='text'
              value={user.name}
              onChange={(e) => onInputChagned('name', e.target.value)}
            />
          </div>
          <div className='input-item'>
            <span className='label'>닉네임</span>
            <input
              type='text'
              value={user.nickname}
              onChange={(e) => onInputChagned('nickname', e.target.value)}
            />
          </div>
          <div className='input-item'>
            <span className='label'>이메일</span>
            <input
              type='text'
              value={user.email}
              onChange={(e) => onInputChagned('email', e.target.value)}
            />
          </div>
        </div>
        <div className='divider'></div>
        <div className='column'>
          <div className='input-item'>
            <span className='label'>전화번호</span>
            <input
              type='text'
              value={user.phone}
              onChange={(e) => onInputChagned('phone', e.target.value)}
            />
          </div>
          <div className='input-item'>
            <span className='label'>생년월일</span>
            <input
              type='text'
              value={user.birth}
              onChange={(e) => onInputChagned('birth', e.target.value)}
            />
          </div>
        </div>
        <div className='divider'></div>
        <div className='column'>
          <div className='input-item'>
            <span className='label'>업로드수</span>
            <div className='disable'>{user.uploadNumber}</div>
          </div>
          <div className='input-item'>
            <span className='label'>생성일자</span>
            <div className='disable'>2022-11-02 22:35:67</div>
          </div>
          <div className='input-item'>
            <span className='label'>삭제일자</span>
            <div className='disable'>-</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnersDetail;
