import useRootRoute from '@hooks/routes/useRootRoute';
import RootNavigation from '@routes/RootNavigation';
import { stringify } from 'querystring';
import React, { useCallback, useEffect, useState } from 'react';

const RootNavigationContainer = () => {
  return <RootNavigation root={'main'} />;
};

export default RootNavigationContainer;
