import image from '@components/common/assets/image';
import CPageNationContainer from '@components/common/CPageNation/containers/CPageNationContainer';
import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import CSearchContainer from '@components/common/CSearch/containers/CSearchContainer';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import {
  PartnersCategoryListItemTypes,
  PartnersListItemTypes,
} from '@typedef/components/Partners/partners.types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/partners.list.styles.css';

type ListItemType = {
  partnersName: string;
  name: string;
  phone: string;
};

type Props = {
  searchInput: string;
  onSearchInputChanged: (value: string) => void;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  partners: ListItemType[];
  maxPage: number;
};

const PartnersList = ({
  currentPage,
  setCurrentPage,
  searchInput,
  onSearchInputChanged,
  partners,
  maxPage,
}: Props) => {
  const navigate = useNavigate();
  return (
    <div className='partners-list-root table-container'>
      <div className='search-section'>
        {/* <form>
          <div className='input-container'>
            <label
              htmlFor='input-partners-search'
              className='input-label'
              hidden>
              {'검색'}
            </label>
            <input
              className='input-box search'
              type='text'
              value={searchInput}
              onChange={(e) => onSearchInputChanged(e.target.value)}
              id='input-partners-search'
              placeholder={'아이디, 이름, 전화번호로 검색해주세요'}
            />
            <button
              className='input-button'
              onClick={(e) => {
                e.preventDefault();
              }}>
              <img src={image.searchBtn} alt='search-btn' />
            </button>
          </div>
        </form> */}
      </div>
      <div className='header-section'>
        <div className='item'>아이디</div>
        <div className='item'>이름</div>
        <div className='item'>전화번호</div>
      </div>
      <div className='body-section'>
        {partners.map((item, index) => (
          <div key={index} className='row' onClick={() => navigate('detail')}>
            <div className='item'>{item.partnersName}</div>
            <div className='item'>{item.name}</div>
            <div className='item'>{item.phone}</div>
          </div>
        ))}
      </div>
      <div className='footer-section'>
        <CPagingContainer
          maxPage={maxPage}
          page={currentPage}
          setPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default PartnersList;
