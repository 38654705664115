import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import {
  FaqListItemTypes,
  FqaTabTypes,
} from '@typedef/components/FAQ/faq.types';
import React from 'react';

type Props = {
  currentPage: number;
  maxPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  faqList: FaqListItemTypes[];
};

const FAQList = ({ currentPage, maxPage, setCurrentPage, faqList }: Props) => {
  return (
    <div className='fqa-list-root table-container'>
      {/* <div className='button-container'>
        <button className='primary-btn'>작성하기</button>
      </div> */}
      <div className='table-container'>
        <div className='header-section'>
          <div className='item'>카테고리</div>
          <div className='item'>제목</div>
          <div className='item'>게시일자</div>
        </div>
        <div className='body-section'>
          {faqList.map((item, idx) => (
            <div key={idx} className='row'>
              <div className='item'>{item.faqCategory.name}</div>
              <div className='item title'>{item.title}</div>
              <div className='item'>{item.createDate}</div>
            </div>
          ))}
        </div>
        <div className='footer-section'>
          <CPagingContainer
            maxPage={maxPage}
            page={currentPage}
            setPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default FAQList;
