import image from '@components/common/assets/image';
import CPageNationContainer from '@components/common/CPageNation/containers/CPageNationContainer';
import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import CSearchContainer from '@components/common/CSearch/containers/CSearchContainer';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import { SettlementListItemTypes } from '@typedef/components/Calc/calc.types';
import React from 'react';
import './styles/calc.styles.css';
import './styles/calc.list.style.css';

type ListItemType = {
  artist: string;
  email: string;
  coin: number;
  requestDate: string;
  price: number | null;
  settlementDate: string | null;
};

const selectOptionList: { label: string; idx: number }[] = [
  { label: '전체보기', idx: -1 },
  { label: '정산완료', idx: 1 },
  { label: '정산가능', idx: 0 },
];

type Props = {
  rate: number;
  settlementList: ListItemType[];
  page: number;
  maxPage: number;
  searchInput: string;
  paymentFrom: string;
  paymentTo: string;
  selectedOption: number;
  onStoreButtonClicked: (index: number) => void;
  onPriceChange: (idx: number, value: string) => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPaymentFrom: React.Dispatch<React.SetStateAction<string>>;
  setPaymentTo: React.Dispatch<React.SetStateAction<string>>;
  onSearchInputChanged: (value: string) => void;
  setSelectedOption: React.Dispatch<React.SetStateAction<number>>;
};

const Calc = ({
  rate,
  settlementList,
  page,
  maxPage,
  searchInput,
  paymentFrom,
  paymentTo,
  selectedOption,
  onStoreButtonClicked,
  onPriceChange,
  setPage,
  setPaymentFrom,
  setPaymentTo,
  setSelectedOption,
  onSearchInputChanged,
}: Props) => {
  return (
    <div className='content-root calc-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'정산관리'}</div>
          <div className='subtitle-label'>{'정산 관리입니다'}</div>
        </div>
      </div>

      <div className='table-container'>
        {/* <div className='setting-section'>
          <label htmlFor='sale-item-size-input'>{'수수료 설정'}</label>
          <div className='input-container'>
            <input
              type='number'
              min={0}
              id='sale-item-size-input'
              value={rate ? rate : ''}
            />
            <span>{'%'}</span>
          </div>
          <button className='primary-btn'>저장하기</button>
        </div> */}
        {/* <div className='search-section'>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}>
            <div className='input-container'>
              <label htmlFor='input-calc-search' className='input-label' hidden>
                {'검색'}
              </label>
              <input
                className='input-box search'
                type='text'
                value={searchInput}
                onChange={(e) => onSearchInputChanged(e.target.value)}
                id='input-calc-search'
                placeholder='주문번호, 가게명, 구매자명으로 검색해주세요'
              />
              <button className='input-button'>
                <img src={image.searchBtn} alt='search-btn' />
              </button>
            </div>
          </form>
          <div className='duration-search-container'>
            <div className='input-wrapper'>
              <input
                type='date'
                className='date-input'
                onChange={(e) => {
                  setPaymentFrom(e.target.value);
                }}
              />
              <span>~</span>
              <input
                type='date'
                className='date-input'
                onChange={(e) => {
                  setPaymentTo(e.target.value);
                }}
              />
            </div>
          </div>
          <div className='select-container'>
            <label htmlFor='item-select' hidden>
              {'정산상태'}
            </label>
            <select
              className={'select-box'}
              placeholder='정산상태'
              name='select-item'
              id='select-item'
              value={selectedOption}
              onChange={(e) => {
                setPage(0);
                setSelectedOption(Number(e.target.value));
              }}
              style={{ background: `url(${image.selectArrow}) no-repeat 90%` }}>
              {selectOptionList.map((item, idx) => {
                return (
                  <option key={idx} value={item.idx} className={'option-store'}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>
        </div> */}
        <div className='header-section settlement-header'>
          <div className='item'>{'이름'}</div>
          <div className='item'>{'이메일'}</div>
          <div className='item'>{'정산요청코인'}</div>
          <div className='item'>{'요청일시'}</div>
          <div className='item'>{'정산금액'}</div>
          <div className='item'> {'정산날짜'}</div>
        </div>
        <div className='body-section'>
          {settlementList.map((item, idx) => {
            return (
              <div className='row' key={idx}>
                <div className='item'>{item.artist}</div>
                <div className='item'>{item.email}</div>
                <div className='item'>
                  {item.coin.toLocaleString() + 'Coin'}
                </div>
                <div className='item'>{item.requestDate}</div>
                <div className='item'>
                  {item.settlementDate ? (
                    item.price!.toLocaleString()
                  ) : (
                    <input
                      className='settlement-input'
                      value={item.price ?? ''}
                      placeholder='금액을 입력해주세요'
                      onChange={(e) => onPriceChange(idx, e.target.value)}
                    />
                  )}
                </div>
                <div className='item'>
                  {item.settlementDate ? (
                    item.settlementDate
                  ) : (
                    <div className='settlement-date-container'>
                      <span className='settlement-date'>-</span>
                      <button
                        className='store-button'
                        onClick={() => onStoreButtonClicked(idx)}>
                        저장
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className='footer-section'>
          <CPagingContainer maxPage={maxPage} page={page} setPage={setPage} />
        </div>
      </div>
    </div>
  );
};

export default Calc;
