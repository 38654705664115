import { images } from '@components/assets/images';
import { GNBItemTypes } from '@typedef/components/common/GNB/gnb.types';
import React from 'react';
import './styles/gnb.styles.css';

const data = [
  {
    label: '사용자',
    tab: 'user',
  },
  {
    label: '아티스트',
    tab: 'partners',
  },
  {
    label: '콘텐츠',
    tab: 'order',
  },
  {
    label: '정산관리',
    tab: 'calculate',
  },
  {
    label: 'FAQ관리',
    tab: 'faq',
  },
] as { label: string; tab: GNBItemTypes }[];

type Props = {
  selectedTab: GNBItemTypes;
  onTabClicked: (tab: GNBItemTypes) => void;
  onLogoutClicked: () => void;
};

const GNB = ({ selectedTab, onTabClicked, onLogoutClicked }: Props) => {
  return (
    <div className='gnb-root'>
      <div className='logo-container'>
        <img src={images.logo} alt='logo' />
      </div>
      {data.map((item, idx) => (
        <div
          key={idx}
          className={`gnb-item-container ${
            selectedTab === item.tab && 'selected'
          }`}
          onClick={() => onTabClicked(item.tab)}>
          <span className='gnb-item-label'>{item.label}</span>
        </div>
      ))}
      <button onClick={onLogoutClicked} className='logout-btn'>
        로그아웃
      </button>
    </div>
  );
};

export default GNB;
