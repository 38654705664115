import GNBContainer from '@components/common/GNB/containers/GNBContainer';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainNavigationContainer from './containers/MainNavigationContainer';

type Props = {
  root: 'login' | 'main';
};

const RootNavigation = ({ root }: Props) => {
  return (
    <BrowserRouter>
      {root === 'main' && <MainNavigationContainer />}
    </BrowserRouter>
  );
};

export default RootNavigation;
