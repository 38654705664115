import {
  CalcQueryParamsTypes,
  SettlementListItemTypes,
} from '@typedef/components/Calc/calc.types';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Calc from '../Calc';

type ListItemType = {
  artist: string;
  email: string;
  coin: number;
  requestDate: string;
  price: number | null;
  settlementDate: string | null;
};

const CalcContainer = () => {
  const [settlementList, setSettlementList] = useState<ListItemType[]>([
    {
      artist: 'testUser',
      email: 'testUser@test.com',
      coin: 1200,
      requestDate: '2022.11.08',
      price: null,
      settlementDate: null,
    },
    {
      artist: 'testUser',
      email: 'testUser@test.com',
      coin: 600,
      requestDate: '2022.11.08',
      price: null,
      settlementDate: null,
    },
    {
      artist: 'testUser',
      email: 'testUser@test.com',
      coin: 300,
      requestDate: '2022.11.08',
      price: null,
      settlementDate: null,
    },
    {
      artist: 'testUser',
      email: 'testUser@test.com',
      coin: 1100,
      requestDate: '2022.11.08',
      price: null,
      settlementDate: null,
    },
    {
      artist: 'testUser',
      email: 'testUser@test.com',
      coin: 100,
      requestDate: '2022.11.08',
      price: null,
      settlementDate: null,
    },
    {
      artist: 'testUser',
      email: 'testUser@test.com',
      coin: 1200,
      requestDate: '2022.11.08',
      price: null,
      settlementDate: null,
    },
    {
      artist: 'testUser',
      email: 'testUser@test.com',
      coin: 1000,
      requestDate: '2022.11.08',
      price: null,
      settlementDate: null,
    },
    {
      artist: 'testUser',
      email: 'testUser@test.com',
      coin: 2000,
      requestDate: '2022.11.08',
      price: 100000,
      settlementDate: '2022.11.08',
    },
  ]);
  const [maxPage, setMaxPage] = useState(0);
  const [page, setPage] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [paymentFrom, setPaymentFrom] = useState('');
  const [paymentTo, setPaymentTo] = useState('');
  const [selectedOption, setSelectedOption] = useState(-1);
  const [rate, setRate] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const onSearchInputChanged = useCallback((value: string) => {
    setSearchInput(value);
  }, []);

  const onPriceChange = useCallback(
    (index: number, value: string) => {
      const number = parseInt(value);
      console.log(number);
      if (!number) {
        return;
      }
      const changed = settlementList.map((item, idx) => {
        if (index !== idx) {
          return item;
        } else {
          return {
            ...item,
            price: number,
          };
        }
      });
      setSettlementList(changed);
    },
    [settlementList],
  );

  const onStoreButtonClicked = useCallback(
    (index: number) => {
      if (!settlementList[index].price) {
        alert('금액을 입력해주세요');
        return;
      }
      const date = new Date();
      const changed = settlementList.map((item, idx) => {
        if (index !== idx) {
          return item;
        } else {
          return {
            ...item,
            settlementDate: `${date.getFullYear()}.${
              date.getMonth() + 1
            }.${date.getDate()}`,
          };
        }
      });
      setSettlementList(changed);
    },
    [settlementList],
  );

  return (
    <Calc
      rate={rate}
      settlementList={settlementList}
      page={page}
      searchInput={searchInput}
      paymentFrom={paymentFrom}
      paymentTo={paymentTo}
      selectedOption={selectedOption}
      setPage={setPage}
      maxPage={maxPage}
      onPriceChange={onPriceChange}
      setPaymentFrom={setPaymentFrom}
      setPaymentTo={setPaymentTo}
      setSelectedOption={setSelectedOption}
      onStoreButtonClicked={onStoreButtonClicked}
      onSearchInputChanged={onSearchInputChanged}
    />
  );
};

export default CalcContainer;
