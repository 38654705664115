import { FqaTabTypes } from '@typedef/components/FAQ/faq.types';
import React from 'react';
import FAQLListContainer from './containers/FAQLListContainer';

type Props = {
  tab: FqaTabTypes;
};

const FAQ = ({ tab }: Props) => {
  return (
    <div className='faq-root content-root'>
      <div className='title-container'>
        <div className={`title-item `}>
          <div className='title-label'>{'FAQ 관리'}</div>
          <div className='subtitle-label'>{'FAQ 관리 페이지 입니다'}</div>
        </div>
      </div>
      <div className='faq-content-container'>
        {tab === 'faq' && <FAQLListContainer />}
      </div>
    </div>
  );
};

export default FAQ;
