import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import React from 'react';
import './styles/login.styles.css';

type Props = {
  id: string;
  pw: string;
  setId: React.Dispatch<React.SetStateAction<string>>;
  setPw: React.Dispatch<React.SetStateAction<string>>;
  onLoginClicked: () => void;
  onCheckEnter: (e: KeyboardEvent) => void;
};

const Login = ({
  id,
  pw,
  setId,
  setPw,
  onLoginClicked,
  onCheckEnter,
}: Props) => {
  return (
    <div className='login-root'>
      <form>
        <input
          type='text'
          value={id}
          onChange={(e) => setId(e.target.value)}
          placeholder={'아이디를 입력해주세요'}
        />
        <input
          type='password'
          value={pw}
          onChange={(e) => setPw(e.target.value)}
          placeholder={'패스워드를 입력해주세요'}
        />
        <button
          className='login-btn'
          type='submit'
          onClick={(e) => {
            e.preventDefault();
            onLoginClicked();
          }}>
          로그인
        </button>
      </form>
    </div>
  );
};

export default Login;
