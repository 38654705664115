import { TabItemTypes } from '@typedef/components/common/GNB/gnb.types';
import {
  UserGenderOptionTypes,
  UserListItemTypes,
  UserQueryParamsTypes,
} from '@typedef/components/User/user.types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RefObject } from 'react';
import { useNavigate } from 'react-router-dom';
import User from '../User';

const UserContainer = () => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState<UserListItemTypes[]>([
    {
      userUid: '1',
      email: 'jinny@gmail.com',
      name: 'Jinny',
      birth: '2000-11-16',
      phone: '010-1111-2222',
      gender: 'M',
      deleteDate: null,
    },
  ]);
  const [maxPage, setMaxPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const [searchBirth, setSearchBirth] = useState('');
  const [genderOptions, setGenderOptions] = useState<UserGenderOptionTypes>([
    { label: '전체보기', value: '' },
    { label: '여성', value: 'F' },
    { label: '남성', value: 'M' },
  ]);
  const [searchGender, setSearchGender] = useState<'F' | 'M' | ''>('');
  const [currentPage, setCurrentPage] = useState(0);

  const onPageChanged = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const onListItemClicked = useCallback(() => {
    navigate('detail');
  }, [navigate]);

  return (
    <User
      userList={userList}
      maxPage={maxPage}
      searchInput={searchInput}
      searchBirth={searchBirth}
      searchGender={searchGender}
      genderOptions={genderOptions}
      onListItemClicked={onListItemClicked}
      setSearchInput={setSearchInput}
      setSearchBirth={setSearchBirth}
      setSearchGender={setSearchGender}
      onPageChanged={onPageChanged}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  );
};

export default UserContainer;
