import { PartnersListItemTypes } from '@typedef/components/Partners/partners.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PartnersList from '../components/PartnersList';

type ListItemType = {
  partnersName: string;
  name: string;
  phone: string;
};

const PartnersListContainer = () => {
  const [partners, setPartners] = useState<ListItemType[]>([
    {
      partnersName: 'testUser@test.com',
      name: 'testUser',
      phone: '010-1111-1111',
    },
  ]);
  const [maxPage, setMaxPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [searchInput, setSearchInput] = useState('');

  const onSearchInputChanged = useCallback((value: string) => {
    setSearchInput(value);
  }, []);

  return (
    <PartnersList
      searchInput={searchInput}
      onSearchInputChanged={onSearchInputChanged}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      partners={partners}
      maxPage={maxPage}
    />
  );
};

export default PartnersListContainer;
