import RootNavigationContainer from '@routes/containers/RootNavigationContainer';
import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import '../styles/core.css';

const App = () => {
  return <RootNavigationContainer />;
};

export default App;
