import { FqaTabTypes } from '@typedef/components/FAQ/faq.types';
import React, { useCallback, useEffect, useState } from 'react';
import FAQ from '../FAQ';

const FAQContainer = () => {
  const [tab, setTab] = useState<FqaTabTypes>('faq');

  return <FAQ tab={tab} />;
};

export default FAQContainer;
