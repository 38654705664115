import useRootRoute from '@hooks/routes/useRootRoute';
import { GNBItemTypes } from '@typedef/components/common/GNB/gnb.types';
import { useEffect } from 'react';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GNB from '../GNB';

const GNBContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clearRootRoute } = useRootRoute();

  const [selectedTab, setSelectedTab] = useState<GNBItemTypes>('user');

  const onTabClicked = useCallback(
    (tab: GNBItemTypes) => {
      setSelectedTab(tab);
      navigate(tab);
    },
    [navigate],
  );

  const onLogoutClicked = useCallback(() => {
    clearRootRoute();

    window.location.reload();
  }, []);

  useEffect(() => {
    const tab = location.pathname.split('/');
    setSelectedTab(tab[1] as GNBItemTypes);
  }, [location]);

  return (
    <GNB
      selectedTab={selectedTab}
      onTabClicked={onTabClicked}
      onLogoutClicked={onLogoutClicked}
    />
  );
};

export default GNBContainer;
