import {
  FaqListItemTypes,
  FqaTabTypes,
} from '@typedef/components/FAQ/faq.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FAQList from '../components/FAQList';

const FAQLListContainer = () => {
  const [faqList, setFaqList] = useState<FaqListItemTypes[]>([]);
  const [maxPage, setMaxpage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  return (
    <FAQList
      currentPage={currentPage}
      faqList={faqList}
      maxPage={maxPage}
      setCurrentPage={setCurrentPage}
    />
  );
};

export default FAQLListContainer;
