import {
  OrderListItemTypes,
  OrderQueryParamsTypes,
  PaymentListItemTypes,
} from '@typedef/components/Order/order.types';
import { PartnersPaymentsTypes } from '@typedef/components/Partners/partners.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Order from '../Order';

const OrderContainer = () => {
  const [paymentList, setPaymentList] = useState<PaymentListItemTypes[]>([]);

  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [paymentFrom, setPaymentFrom] = useState('');
  const [paymentTo, setPaymentTo] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  const onSearchInputChanged = useCallback((value: string) => {
    setSearchInput(value);
  }, []);

  return (
    <Order
      paymentList={paymentList}
      maxPage={maxPage}
      currentPage={currentPage}
      searchInput={searchInput}
      paymentFrom={paymentFrom}
      paymentTo={paymentTo}
      setCurrentPage={setCurrentPage}
      onSearchInputChanged={onSearchInputChanged}
      setSearchInput={setSearchInput}
      setPaymentFrom={setPaymentFrom}
      setPaymentTo={setPaymentTo}
    />
  );
};

export default OrderContainer;
