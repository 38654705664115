import React from 'react';
import './styles/cpaging.styles.css';

type Props = {
  page: number;
  pageList: number[];
  onFirstPageClicked: () => void;
  onPrevPageClicked: () => void;
  onPageClicked: (page: number) => void;
  onNextPageClicked: () => void;
  onLastPageClicked: () => void;
};

const CPaging = ({
  page,
  pageList,
  onFirstPageClicked,
  onPrevPageClicked,
  onPageClicked,
  onNextPageClicked,
  onLastPageClicked,
}: Props) => {
  return (
    <div className={'cpaging-root'}>
      <button onClick={onFirstPageClicked} className={'move-btn'}>
        {'<<'}
      </button>
      <button onClick={onPrevPageClicked} className={'move-btn'}>
        {'<'}
      </button>
      {pageList.map((n, i) => (
        <button
          onClick={() => onPageClicked(n)}
          key={i}
          className={`${'number-btn'} ${page === n && 'current'}`}>
          {n + 1}
        </button>
      ))}
      <button onClick={onNextPageClicked} className={'move-btn'}>
        {'>'}
      </button>
      <button onClick={onLastPageClicked} className={'move-btn'}>
        {'>>'}
      </button>
    </div>
  );
};

export default CPaging;
