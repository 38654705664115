import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import {
  UserGenderOptionTypes,
  UserListItemTypes,
} from '@typedef/components/User/user.types';
import React from 'react';

import './styles/user.styles.css';

type Props = {
  userList: UserListItemTypes[];
  maxPage: number;
  currentPage: number;
  searchInput: string;
  searchBirth: string;
  searchGender: 'F' | 'M' | '';
  genderOptions: UserGenderOptionTypes;
  onListItemClicked: () => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  setSearchBirth: React.Dispatch<React.SetStateAction<string>>;
  setSearchGender: React.Dispatch<React.SetStateAction<'F' | 'M' | ''>>;
  onPageChanged: (page: number) => void;
};

const User = ({
  userList,
  maxPage,
  currentPage,
  searchBirth,
  searchGender,
  searchInput,
  genderOptions,
  onListItemClicked,
  setCurrentPage,
  setSearchInput,
  setSearchBirth,
  setSearchGender,
  onPageChanged,
}: Props) => {
  console.log(currentPage, maxPage);

  return (
    <div className='content-root user'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'사용자 목록'}</div>
          <div className='subtitle-label'>{'사용자 목록 페이지 입니다'}</div>
        </div>
      </div>

      <div className='table-container'>
        <div className='header-section'>
          <div className='item'>이메일</div>
          <div className='item'>이름</div>
          <div className='item'>전화번호</div>
          <div className='item'>생년월일</div>
          <div className='item'>성별</div>
        </div>
        <div className='body-section'>
          {userList.map((item, idx) => (
            <div key={idx} className='row' onClick={onListItemClicked}>
              <div className='item'>{item.email}</div>
              <div className='item'>{item.name}</div>
              <div className='item'>{item.phone}</div>
              <div className='item'>{item.birth}</div>
              <div className='item'>
                {item.gender === 'F' ? '여성' : '남성'}
              </div>
            </div>
          ))}
        </div>
        <div className='footer-section'>
          <CPagingContainer
            maxPage={maxPage}
            page={currentPage}
            setPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default User;
