import useRootRoute from '@hooks/routes/useRootRoute';

import { alertMessage } from '@libs/alertMessage';

import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Login from '../Login';

const LoginContainer = () => {
  const navigate = useNavigate();

  const [id, setId] = useState('');
  const [pw, setPw] = useState('');

  const onLoginClicked = useCallback(() => {
    if (id !== 'test' || pw !== 'testtest') {
      alert('아이디 비밀번호를 다시 확인해주세요');
      return;
    }
    navigate('/user');
  }, [id, pw]);

  const onCheckEnter = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onLoginClicked();
    }
  }, []);

  return (
    <Login
      id={id}
      pw={pw}
      setId={setId}
      setPw={setPw}
      onLoginClicked={onLoginClicked}
      onCheckEnter={onCheckEnter}
    />
  );
};

export default LoginContainer;
