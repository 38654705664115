import { PartnersTabTypes } from '@typedef/components/Partners/partners.types';
import React from 'react';
import PartnersListContainer from './containers/PartnersListContainer';

import './styles/partners.styles.css';

type Props = {
  tab: PartnersTabTypes;
  onTabClicked: (tab: PartnersTabTypes) => void;
};

const Partners = ({ tab, onTabClicked }: Props) => {
  return (
    <div className='partners-root content-root'>
      <div className='title-container'>
        <div onClick={() => onTabClicked('partners')} className={`title-item `}>
          <div className='title-label'>{'파트너스 목록'}</div>
          <div className='subtitle-label'>{'가입한 파트너스 목록 입니다'}</div>
        </div>
      </div>
      <div className='partners-content-container'>
        {tab === 'partners' && <PartnersListContainer />}
      </div>
    </div>
  );
};

export default Partners;
