import React, { useCallback, useEffect, useMemo } from 'react';
import CPaging from '../CPaging';

type Props = {
  maxPage: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const CPagingContainer = ({ maxPage, page, setPage }: Props) => {
  const startPage = useMemo(() => Math.floor(page / 10), [page]);

  const endPage = useMemo(
    () => Math.min(maxPage, startPage + 9),
    [maxPage, startPage],
  );

  const pageList = useMemo(
    () =>
      Array.from(new Array(endPage - startPage + 1).keys()).map(
        (item) => item + startPage,
      ),
    [startPage, endPage],
  );

  const onFirstPageClicked = useCallback(() => {
    setPage(0);
  }, [setPage]);

  const onPrevPageClicked = useCallback(() => {
    setPage(Math.max(0, page - 1));
  }, [page]);

  const onPageClicked = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage],
  );

  const onNextPageClicked = useCallback(() => {
    setPage(Math.min(page + 1, maxPage));
  }, [setPage, page, maxPage]);

  const onLastPageClicked = useCallback(() => {
    setPage(maxPage);
  }, [setPage, maxPage]);

  return (
    <CPaging
      page={page}
      pageList={pageList}
      onFirstPageClicked={onFirstPageClicked}
      onPrevPageClicked={onPrevPageClicked}
      onPageClicked={onPageClicked}
      onNextPageClicked={onNextPageClicked}
      onLastPageClicked={onLastPageClicked}
    />
  );
};

export default CPagingContainer;
