import CalcContainer from '@components/Calc/containers/CalcContainer';
import GNBContainer from '@components/common/GNB/containers/GNBContainer';
import FAQContainer from '@components/FAQ/containers/FAQContainer';
import LoginContainer from '@components/Login/containers/LoginContainer';
import NotFound from '@components/NotFound/NotFound';
import OrderContainer from '@components/Order/containers/OrderContainer';
import PartnersDetail from '@components/Partners/components/PartnersDetail';
import PartnersContainer from '@components/Partners/containers/PartnersContainer';
import UserDetail from '@components/User/components/UserDetail';
import UserContainer from '@components/User/containers/UserConatiner';
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import '../styles/main.navigation.styles.css';

const MainNavigation = () => {
  const location = useLocation();
  return (
    <div className='main-navitaion-root'>
      {!(location.pathname === '/') && <GNBContainer />}

      <div className='content'>
        <Routes>
          <Route>
            <Route path='/' element={<LoginContainer />} />
          </Route>
          <Route path='/user'>
            <Route path='' element={<UserContainer />} />
            <Route path='detail' element={<UserDetail />} />
          </Route>
          <Route path='/partners'>
            <Route path='' element={<PartnersContainer />} />
            <Route path='detail' element={<PartnersDetail />} />
          </Route>
          <Route>
            <Route path='/order' element={<OrderContainer />} />
          </Route>
          <Route>
            <Route path='/calculate' element={<CalcContainer />} />
          </Route>
          <Route>
            <Route path='/faq' element={<FAQContainer />} />
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
};

export default MainNavigation;
